import React from 'react';
import styled from 'styled-components';
import { Text, StdText } from '../Text';
import { Inner } from '../Inner';
import { HH_LINK, PROJECTS } from '../../constants';

const Wrapper = styled(Inner)`
  display: flex;
  margin-bottom: 100px;
  @media (max-width: 1169px) {
    flex-wrap: wrap;
    padding-left: 48px;
    padding-right: 48px;
  }
`;

const SProjectList = styled.div`
  margin-right: 44px;
  flex-grow: 1;
  @media (max-width: 1169px) {
    margin-right: 0;
  }
`;

const STitle = styled.div`
  margin-bottom: 30px;
`;

const SItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SItem = styled.div`
  max-width: 350px;
  margin-bottom: 45px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1169px) {
    max-width: 320px;
  }
  @media (max-width: 768px) {
    max-width: none;
    width: 100%;
  }
`;

const SItemTitle = styled.div`
  margin-bottom: 20px;
`;

const SItemLink = styled.div`
  padding: 6px 0;
  border-top: 1px solid ${({ theme }) => theme.textSecondaryDarkColor};
  border-bottom: 1px solid ${({ theme }) => theme.textSecondaryDarkColor};
  position: relative;
  margin-bottom: 12px;
  /* &:after {
    content: '';
    height: 13px;
    width: 13px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-image: url(${process.env.PUBLIC_URL + `/images/link-arrow.svg`});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  } */
`;

const SBanner = styled.div`
  cursor: pointer;
  position: relative;
  padding: 90px 60px;
  flex-grow: 1;
  border: 3px solid ${({ theme }) => theme.textDarkColor};
  border-radius: 0px 50px;
  max-width: 390px;
  display: flex;
  align-items: center;
  &:after {
    content: '';
    height: 37px;
    width: 37px;
    bottom: 16px;
    right: 16px;
    background-image: url(${process.env.PUBLIC_URL + `/images/link-arrow.svg`});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 1169px) {
    max-width: none;
  }
  @media (max-width: 768px) {
    padding: 90px 15px;
  }
`;

function WorkingOn() {
  return (
    <Wrapper id="working-on">
      <SProjectList>
        <STitle>
          <Text uppercase fontSize={15} fontWeight={600} lineHeight={'19px'} letterSpacing={3}>В настоящее время мы работаем над</Text>
        </STitle>
        <SItems>
          {PROJECTS.map((el, id) => (
            <SItem>
              <SItemTitle className="">
                <StdText type='h4' uppercase fontWeight={600}>{el.title}</StdText>
              </SItemTitle>
              {el.link && (
                <SItemLink>
                  <Text fontSize={19} as={'a'} href={el.link} target="_blank">{el.linkTitle}</Text>
                </SItemLink>
              )}
              <div>
                <Text fontSize={19}>{el.description}</Text>
              </div>
            </SItem>
          ))}
        </SItems>
      </SProjectList>
      <SBanner>
        <StdText type='h3' uppercase fontWeight="600" href={HH_LINK} target='_blank' as="a">Просмотреть все вакансии</StdText>
      </SBanner>
    </Wrapper>
  )
}

export default WorkingOn;