import React from "react";
import { NavLink } from "react-router-dom";
import { NAV_ITEMS } from "../../constants";
import { Close, Item, Wrapper } from "./styled";

interface IMobileMenu {
  isOpen?: boolean;
  onClose: () => any
}

const MobileMenu: React.FC<IMobileMenu> = ({ isOpen, onClose }) => {
  return (
    <Wrapper active={isOpen}>
      <Close onClick={onClose} >Закрыть</Close>
      {NAV_ITEMS.map((el, i) => <NavLink to={el.href}><Item>{el.title}</Item></NavLink>)}
    </Wrapper>
  )
}

export default MobileMenu;
