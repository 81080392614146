import React from "react";
import { ConditionIcon, ConditionsWrapper, ConditionTitle, STitle, SInner, ConditionWrapper, ConditionText } from "./styled";
import ScheduleImg from './img/schedule.svg';
import SalaryImg from './img/salary.svg';
import ExperienceImg from './img/experience.svg';
// import CareImg from './img/care.svg';

const conditions = [
  {
    icon: ExperienceImg,
    title: 'Офисы',
    text: 'Стремимся создать максимально комфортные условия для сотрудников.', 
  },
  {
    icon: ScheduleImg,
    title: 'Рабочий график',
    text: 'Мы предоставляем гибкий график работы. Наши сотрудники имеют возможность выбирать, где им работать дома или в офисе, каждый работает в удобное для него время.', 
  },
  {
    icon: SalaryImg,
    title: 'Зарплата',
    text: 'Мы предлагаем достойную оплату, с перспективой многолетнего высокого стабильного заработка.', 
  }
]

const WorkConditions: React.FC<{}> = () => {
  return <SInner>
    <STitle type="h2" fontWeight={700}>
      Условия труда
    </STitle>
    <ConditionsWrapper>
      {conditions.map((el, i) => (
        <ConditionWrapper key={i} bottom={!!(i % 2)}>
          <ConditionIcon><img src={el.icon} alt={el.title} /></ConditionIcon>
          <ConditionTitle as="div" fontWeight={600} fontSize={24}>{el.title}</ConditionTitle>
          <ConditionText as="div">{el.text}</ConditionText>
        </ConditionWrapper>
      ))}
    </ConditionsWrapper>
  </SInner>;
}

export default WorkConditions;