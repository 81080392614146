import React from 'react';
import ReactDOM from 'react-dom';
import { theme } from './theme';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import IndexPage from './routes';
import AboutUsPage from './routes/about-us';
import HowWeWorkPage from './routes/how-we-work';
import CareerPage from './routes/career';
import ContactsPage from './routes/contacts';

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/how-we-work" element={<HowWeWorkPage />} />
        <Route path="/career" element={<CareerPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
      </Routes>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

