import styled from "styled-components";
import { Inner } from "../Inner";
import { StdText, Text } from "../Text";

interface IExpanableItem {
  expanded?: boolean;
}

export const Wrapper = styled.div`
  padding: 130px 0;
`;

export const SInner = styled(Inner)`
  @media (max-width: 768px) {
    padding: 0 35px;
  }
`;

export const ExpandableListTitle = styled(StdText)`
  margin-bottom: 25px;
`;

export const ExpandableItem = styled.div`
  display: flex;
  padding: 20px 0;
  border-top: 1px solid #A5A5A5;
  border-bottom: 1px solid #A5A5A5;
  cursor: pointer;
`;

export const ExpandableItemTitle = styled(Text)`
  margin-right: 20px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const ExpandableItemSubTitle = styled(Text)`
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const ExpandableItemImg = styled.img<IExpanableItem>`
  width: 28px;
  transition: all 0.2s ease-out;
  transform: ${({ expanded }) => expanded ? 'rotate(45deg)' : 'none'};
`;

export const ExpandedItem = styled.div<IExpanableItem>`
  overflow: hidden;
  max-height: ${({ expanded }) => expanded ? 'none': 0};
`;

export const ExpandedInner = styled.div`
  padding: 15px;
`;