import styled from "styled-components";

interface IWrapper {
  active?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  ${({ active }) => active && 'display: flex;'}
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  z-index: 999;
  padding: 45px 36px 0;
`;

export const Close = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;

  color: #E60000;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    align-self: flex-end;
  }
`;

export const Item = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-decoration-line: underline;

  color: #FFFFFF;
  margin-bottom: 33px;
`;