import styled from 'styled-components'

const StyledNews = styled.section`
    padding: 67px 0 0;
    overflow: hidden;
    & .wrapper{
        
    }
    & .header{
        display: flex;
        align-items: center;
        position: relative;
        height: 47px;
        & .all{
            color: #484848;
        }
        & .title{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #323232;
            font-size: 36px;
        }
    }
    & .slide__link{
        color: #323232;
        text-transform: capitalize;
    }
    & .slick-slide{
        border: 2px solid #CECECE;
        border-radius: 50px 0px;
        width: 353px;
        height: 194px;
        margin-right: 20px;
        padding: 29px 34px 23px;
        & > div{
            width: 100%;
            height: 100%;
        }
        &:nth-child(even){
            border-radius: 0px 50px;
        }
    }
    & .slide{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        &__p{
            color: #484848;
            margin: 0;
            font-size: 19px;
            line-height: 25px;
        }
    }
    & .slick-track{
        display: flex;
    }
    & .slick-arrow{
        position: absolute;
        top: -58px;
        right: 0;
        content: '';
        color: transparent;
        width: 48px;
        height: 26px;
        transform: translateY(-50%);
        border: none;
        cursor: pointer;
        background: url(${process.env.PUBLIC_URL + `/images/arrow-right.svg`});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    & .slick-prev{
        height: 18px;
        width: 32px;
        right: 80px;
        background: url(${process.env.PUBLIC_URL + `/images/arrow-left.svg`});
    }
    & .slick{
        border-bottom: 1px solid #CECECE;
        padding-bottom: 66px;

        position: relative;
        margin-top: 35px;
        &::before{
            position: absolute;
            content: '';
            height: 100%;
            display: block;
            width: calc((100vw - 1170px) / 2);
            z-index: 1;
            background: #f0f0f0;
            top: 0;
            left: calc(-1 * (100vw - 1170px) / 2);
        }
    }
    @media (max-width: 768px) {
        padding: 67px 40px 0;
        & .header {
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            & .all {
                margin-bottom: 10px;;
            }
            & .title {
                position: initial;
                top: initial;
                left: initial;
                transform: none;
                margin-bottom: 56px;
            }
        }

        & .slick-arrow{
            left: 0;
        }
        & .slick-prev{
            right: initial;
        }
        & .slick-next{
            left: 80px;
        }
    }
`

export default StyledNews