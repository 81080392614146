import React from 'react'
import styles from './style.module.css'
import Wrapper from '../Wrapper'
import Button from '../Button'
// import quote from './img/quotes.svg'
import proto from './img/proto.png'
import { CONTACTS } from '../../constants'

function Hero(){
    return(
        <Wrapper>
            <div className={styles.hero__container}>
                <div className={styles.hero__content}>
                    <h1 className={styles.hero__title}>Искусство разработки технологичных решений на острие прогресса</h1>
                    <p className={styles.hero__desc}>Мы превращаем разработку сложнейших высоконагруженных решений в области торговых систем и блокчейн в искусство, применяя самые передовые технологии и подходы.</p>
					<Button onClick={() => {
						window.open(CONTACTS.telegram)
					}} text='Связаться'></Button>
                </div>
                <div className={styles.hero__photo}>
                    {/* <div className={styles.hero__square1}></div> */}
                    <div className={styles.hero__square2}></div>
                    <div className={styles.hero__square3}></div>
                    <img src={proto} alt="" className={styles.hero__picture} />
                </div>
                <div className={styles.hero__quote}>
                    <div className={styles.hero__qbox}>
                        <p className={styles.hero__qp}>Когда заканчивается рабочий день, но останавливаться не хочется, то понимаешь, какой должна быть работа мечты.</p>
                        {/* <span className={styles.hero__qtitle}>Miago Sanchez</span>
                        <span className={styles.hero__job}>Marketing Manager</span> */}
                    </div>
                </div>
            </div>

        </Wrapper>
    )
    
}

export default Hero