import styled from 'styled-components'
// import React from 'react'

const StyledFooter = styled.footer`
    padding-top: 97px;
    & .box{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 61px;
    }
    & .row {
        display: flex;
        justify-content: space-between;
        width: 300px;
    }
    & .col{
        display: flex;
        flex-direction: column;
        & *:not(:last-child){
            margin-bottom: 10px;
        }
    }
    & .title{
        margin: 0;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        color: #323232;
    }
    & .li{
        font-size: 19px;
        line-height: 25px;
        color: #484848;
        cursor: pointer;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
    & .slogan{
        font-size: 19px;
        line-height: 25px;
        color: #484848;
    }
    & .adress{
        color: #484848;
        max-width: 265px;
        font-size: 19px;
        line-height: 25px;
        margin: 0;
    }
    & .logo{
        width: 285px;
        height: 37px;
        margin-bottom: 16px;
        background-image: url(${process.env.PUBLIC_URL + `/images/streamtech.svg`});
    }
    & .subfooter{
        width: 100%;
        height: 56px;
        background: black;
        color: white;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media (max-width: 1169px) {
        & .row {
            width: 270px;
        }
    }
    @media (max-width: 768px) {
        & > * {
            
            padding: 0 40px;
        }
        & .box {
            flex-direction: column;
            justify-content: space-between;
        }
        & .col {
            margin-bottom: 20px;
        }
        & .col:last-child {
            max-width: 260px;
        }
    }
`

export default StyledFooter