import React from "react";
import { CoolImg, FutureTitle, SInner } from "./styled";
import { HistoryTitle, Wrapper, SText, HistoryWrapper, HistoryItem, HistoryYear, HistoryDash, HistoryTextBlock, FutureBlock } from "./styled";
import DashImg from './img/dash.svg'
import CoolFaceImg from './img/cool-face.png'

const history = [
  {
    // year: "",
    text: "Ключевые люди команды получали опыт за пределами Streamtech.",
  },
  {
    year: 2019,
    text: "С проектом ZUBR стартует работа команды по разработке сложнейших высоконагруженных решений в области торговых систем и блокчейн.",
  },
  {
    year: 2021,
    text: "Стартует работа по проекту Tranches Texture Finance. Разработчики команды передают накопленный опыт в работе над самыми передовыми раст-проектами..",
  },
]

const History: React.FC<{}> = () => {
  return <Wrapper>
    <SInner>
      <HistoryTitle type="h2" fontWeight={700}>Наша история</HistoryTitle>
      {/* <SText fontSize={25} lineHeight="31px" color="rgba(64, 64, 64, 0.8)">
        We are a privately owned, financially stable company who through steady organic growth, much through word of mouth, have become an integral 
      </SText> */}
      <HistoryWrapper>
        {history.map((el, i) => (
          <HistoryItem key={i} reverse={i % 2 !== 0}>
            <HistoryYear>{el.year}</HistoryYear>
            <HistoryDash  src={DashImg} />
            <HistoryTextBlock>{el.text}</HistoryTextBlock>
          </HistoryItem>
        ))}
      </HistoryWrapper>
      <FutureBlock>
        <CoolImg src={CoolFaceImg} />
        <FutureTitle type="h2" fontWeight={700}>Что в будущем?</FutureTitle>
        <SText fontSize={25} lineHeight="31px" color="rgba(64, 64, 64, 0.8)">
          Рост команды, открытие вакансий по всей стране, сотрудничество с ВУЗами РФ. Удобные офисы в Москве и Санкт-Петербурге.
        </SText>
      </FutureBlock>
    </SInner>
  </Wrapper>
}

export default History;