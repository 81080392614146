
import styled from 'styled-components';
import { Inner } from '../Inner'
import { Text } from '../Text';
import { ContactItem } from "../CareerContent/styled";

export const Wrapper = styled.div`
  height: 825px;
  background-color: ${({ theme }) => theme.textBlackColor};
  border-radius: 0px 0px 50px 0px;
  &:after {
    background-image: url(${process.env.PUBLIC_URL + `/images/flow-about.svg`});
    background-position: left top;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 900px;
    width: 1110px;
  }
  background: ${({ theme }) => theme.textBlackColor} url(${process.env.PUBLIC_URL + `/images/career-background.png`}) no-repeat;
  background-size: 579px 680px;
  background-position-y: bottom;
  @media (max-width: 1169px) {
    position: relative;
    background-image: none;
    &:after {
      height: 100%;
      width: 100%;
    }
  }
`;

export const SInner = styled(Inner)`
  padding-top: 146px;
  padding-left: 320px;
  height: calc(100% - 146px);
  display: flex;
  align-items: center;
  @media (max-width: 1750px) {
    margin-left: 280px;
  }
  @media (max-width: 1169px) {
    padding-left: 0;
    margin-left: auto;
  }
  @media (max-width: 768px) {
    padding: 146px 30px 0;
  }
`;

export const ContentWrapper = styled.div`
`;

export const STitleText = styled(Text)`
  color: ${({ theme }) => theme.textWhiteColor};
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 45px;
  }
`;

export const SContactItem = styled(ContactItem)`
  color: ${({ theme }) => theme.textWhiteColor};
  font-size: 24px;
  margin-right: 48px;
  margin-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`

export const SSubText = styled(Text)`
  color: ${({ theme }) => theme.textSecondaryGrayColor};
  max-width: 550px;
`;
