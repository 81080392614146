import styled from 'styled-components';

const AboutContent = styled.div`
  background: url(${process.env.PUBLIC_URL + `/images/about-background.svg`}) no-repeat;
  background-position: 100% 164px;
  
  @media (max-width: 1169px) {
    background-position: 100% 120px;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
    background: none;
  }
`;

export default AboutContent;