import React, { useState } from 'react'
import styles from './style.module.css'
import logo from './img/logo.svg'
import whiteLogo from './img/logo-white.svg'
import Wrapper from '../Wrapper'
import Button from '../Button'
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames'
import { CONTACTS, NAV_ITEMS } from '../../constants'
import MobileMenu from '../MobileMenu'

interface IHeader {
	isWhite?: boolean;
}

function Header({ isWhite }: IHeader){
	const [isOpen, setIsOpen] = useState<boolean>(false);
	return(
		<header className={classNames(styles.header, isWhite && styles['_white'])}>
			<Wrapper>
				<div className={styles.header__wrapper}>
					<Link to={'/'}>
						<img src={isWhite ? whiteLogo : logo} alt="Streamtech_Logo" className={styles.header__logo} />
					</Link>
					<nav className={styles.header__nav}>
						{NAV_ITEMS.map((el, i) => (
							<NavLink to={el.href} className={({ isActive }) => isActive ? `${styles.header__link} ${styles['_active']}` : styles.header__link}>{el.title}</NavLink>
						))}
					</nav>
					<div
						className={styles.burger}
						onClick={
							() =>{
								setIsOpen(true);
							}
						}
					>
						Навигация
					</div>
					<Button onClick={() => {
						window.open(`mailto:${CONTACTS.email}`)
					}} text='Связаться' isWhite={isWhite}></Button>
				</div>
				<MobileMenu isOpen={isOpen} onClose={() => setIsOpen(false)} />
			</Wrapper> 
		</header>
	)
}

export default Header