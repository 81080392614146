import React, { useState } from 'react';
import { SInner, Wrapper, ExpandableListTitle, ExpandableItem , ExpandableItemTitle, ExpandableItemSubTitle, ExpandableItemImg, ExpandedItem, ExpandedInner} from './styled';
import { Row } from '../Row';
import CloseOpenImg from './img/close-open.svg';

interface IExpandableListItem {
  title: string;
  text?: string;
  content: () => JSX.Element,
}

interface IExpandableList {
  title: string;
  list: IExpandableListItem[];
}

const ExpandableList: React.FC<IExpandableList> = ({ title, list }) => {
  const [expandedeItemId, setExpandedItemId] = useState<number>();
  return <Wrapper>
    <SInner>
      <ExpandableListTitle type="h2" fontWeight={700}>{title}</ExpandableListTitle>
      <div style={{
        marginTop: '25px',
      }}>
        {list.map((el, i) => (
          <div>
            <ExpandableItem key={i} onClick={() => {
              if (expandedeItemId === i) {
                setExpandedItemId(undefined);
              } else setExpandedItemId(i)
            }}>
              <Row>
                <ExpandableItemTitle fontSize={25} fontWeight={600} lineHeight={'31px'}>{el.title}</ExpandableItemTitle>
                {el.text && <ExpandableItemSubTitle fontSize={25} fontWeight={300} lineHeight={'31px'} themeColor="textSecondaryDarkColor">{el.text}</ExpandableItemSubTitle>}
              </Row>
              <ExpandableItemImg src={CloseOpenImg} expanded={expandedeItemId === i} />
            </ExpandableItem>
            <ExpandedItem expanded={expandedeItemId === i}>
              <ExpandedInner>
                {el.content()}
              </ExpandedInner>
            </ExpandedItem>
          </div>
        ))}
      </div>
    </SInner>
  </Wrapper>;
}

export default ExpandableList;