import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import BlackBlock from '../../components/BlackBlock';
import News from '../../components/News';
import ExpandableList from '../../components/ExpandableList';
import PointsBlock from '../../components/PointsBlock';
import HighQualityImg from './img/high-quality.svg';
import ResponsibleImg from './img/responsible.svg';
import CodeImg from './img/code.png';
import { PROJECTS } from '../../constants';

// const projectList = [
//   {
//     title: 'Zubr',
//     text: 'Crypto trading platform',
//     content: () => (
//       <div>
//         <p>Some description</p>
//         <ul>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//         </ul>
//       </div>
//     )
//   },
//   {
//     title: 'Solana',
//     text: 'Blockchain ecosystem',
//     content: () => (
//       <div>
//         <p>Some description</p>
//         <ul>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//           <li>1</li>
//         </ul>
//       </div>
//     )
//   },
// ];

const projectList = PROJECTS.map(el => ({
  title: el.title,
  content: () => (
    <div>
      {el.description}
    </div>
  )
}))

const pointsList = [
  {
    title: "Участие в проектировании решений разработчиков",
    img: HighQualityImg,
  },
  {
    title: "Великолепная команда Q&A",
    img: ResponsibleImg,
  },
  {
    title: "Обратная связь от заказчика",
    img: CodeImg,
  }
];

const principles = () => (
  <div>
    <ul>
      <li>Открытость. Мы делаем максимально прозрачными любые процессы внутри компании. Любой руководитель всегда находит время для любого сотрудника, а любые решения обосновываются и объясняются.</li>
      <li>Участие. Мы считаем, что отличные результаты возможны только в хороших условиях и за соответствующее вознаграждение. Мы внимательно и индивидуально подходим к потребностям коллег.</li>
      <li>Профессиональный рост. У нас не нужно становиться руководителем, чтобы расти. Мы ценим профильных специалистов не меньше, чем хороших управленцев.</li>
      <li>Качество. Мы делаем только качественные сервисы и считаем, что дополнительные затраты ресурсов на качество оправданы.</li>
      <li>Вовлеченность. Нам нравится наша работа и нам интересно то, что мы делаем. Каждый член нашей команды заинтересован в результате, а не в окончании рабочего дня. </li>
    </ul>
  </div>
)

const HowWeWorkPage = () => (
    <>
      <Header isWhite />
      <BlackBlock title="Как мы работаем" text={`Наша работа – это не простой и творческий процесс. Мы занимаемся самыми сложными и захватывающими задачами в индустрии и доводим результат до совершенства, чтобы каждый участник команды мог с гордостью сказать: «Я принимал участие в этом проекте».
        Мы не ограничиваем себя выбором очевидных или простых подходов. Каждый участник наших проектов имеет некоторую свободу для творчества в ходе реализации. Со свободой приходит и ответственность, и гордость за выполненный результат.
        Мы выполняем полный цикл разработки. Наши продукты, проходят весь путь от бизнес-идеи до запуска в эксплуатацию, в том числе многостороннее и глубокое тестирование.`}
        content={() => (
          <>
            <p>Наша работа – это не простой и творческий процесс. Мы занимаемся самыми сложными и захватывающими задачами в индустрии и доводим результат до совершенства, чтобы каждый участник команды мог с гордостью сказать: «Я принимал участие в этом проекте».</p>
            <p>Мы не ограничиваем себя выбором очевидных или простых подходов. Каждый участник наших проектов имеет некоторую свободу для творчества в ходе реализации. Со свободой приходит и ответственность, и гордость за выполненный результат.</p>
            <p>Мы выполняем полный цикл разработки. Наши продукты, проходят весь путь от бизнес-идеи до запуска в эксплуатацию, в том числе многостороннее и глубокое тестирование.</p>
          </>
        )}
        />
      <ExpandableList title="Актуальные проекты" list={projectList} />
      <PointsBlock title="Принципы" content={principles} list={pointsList} /> 
      <News/>
      <Footer/>
    </>
);

export default HowWeWorkPage;
