import React, { useEffect, useRef } from 'react';
import { SInner, Wrapper, VacanciesTitle } from './styled';


interface IVacancies {
  title: string;
}

const Vacancies: React.FC<IVacancies> = ({ title }) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if(ref.current) {
      const script = document.createElement('script');
      script.className = "hh-script";
      script.src = "https://api.hh.ru/widgets/vacancies/employer?employer_id=5721799&locale=RU&links_color=1560b2&border_color=1560b2";
      script.async = true;
      ref.current && ref.current.appendChild(script);

      return () => {
        delete (window as any).hhScriptCounter;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (ref.current) ref.current.removeChild(script);
      }
    }
  
  }, [ref]);
  return <Wrapper>
    <SInner>
      <VacanciesTitle type="h2" fontWeight={700}>{title}</VacanciesTitle>
      <p>В настоящий момент вакансий нет.</p>
      {/*<div ref={ref} style={{*/}
      {/*  marginTop: '25px',*/}
      {/*}}>*/}
      {/*</div>*/}
    </SInner>
  </Wrapper>;
}

export default Vacancies;