import React from 'react'
import { BlockStyle } from './style'



function Block(props: { [key in string]: any | undefined }){
    const res = [];
    for(let i in props){
        if(i.toString().includes('title')){
            res.push(
                <h4 className="title">{props[i]}</h4>
            )
        }
        if(i.toString().includes('h2_solo')){
            res.push(
                <h4 className="h2">{props[i]}</h4>
            )
        }
        if(i.toString().includes('paragraph')){
            res.push(
                <h4 className="paragraph">{props[i]}</h4>
            )
        }
        if(i.toString().includes('icon_wide')){
            res.push(
                <div className='img_container'>
                    <img className="icon_wide" src={process.env.PUBLIC_URL + `/images/${props[i]}`} alt=''></img>
                </div>
            )
        }
        if(i.toString().includes('h2_logo')){
            res.push(
                <div className="logobox">
                    <img alt="" className="icon_inline" src={process.env.PUBLIC_URL + `/images/${props[i][0]}`} />
                    <h2 className="h2 inline">{props[i][1]}</h2>
                </div>
            )
        }
    }
    return(
        <BlockStyle>
            {res}
            
        </BlockStyle>
    )
}

export default Block