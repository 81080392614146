import React, { Component } from 'react'
import Slider from 'react-slick'
import { SliderStyle } from './style';
import Wrapper from '../Wrapper';
import Block from './Block';
import { list } from './block-list'


export default class Blocks extends Component {
    render() {
      const settings = {
        speed: 500,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1169,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ],
      };

      const content = list.map((block) =>
        <Block {...block} />
      )
      return (
        <section>
            <Wrapper>
                <SliderStyle>
                    <Slider {...settings}>
                        {content}
                    </Slider>
                </SliderStyle>
            </Wrapper>
            
          
        </section>
        
      );
    }
  }
  
