import styled from 'styled-components'

export const SliderStyle = styled.div`
    position: relative;
    z-index: 20;
    margin-bottom: 100px;
    & * {
        box-sizing: border-box;
    }
    & .slick-slider{
        overflow: hidden;
        
    }    
    & .slick-track {
        display: flex;
        & .slick-slide{
            padding: 0;
            &:nth-child(even){
                border-radius: 50px 0px !important;
                & > div > div{
                    background-image: url(${process.env.PUBLIC_URL + `/images/flow-block.svg`});
                    background-repeat: no-repeat;
                    background-position: right bottom;
                }
            }
            &:last-of-type{
                margin-right: 0;
            }
            border-radius: 0px 50px;
            min-width: 382px ;
            height: 530px ;
            margin-right: 10px;
            overflow: hidden;
        }
    }
    
    .slick-slide > div{
        width: 100%;
        height:100%;
    }
    @media (max-width: 768px) {

        & .slick-track {
            & .slick-slide{
                min-width: 0;
            }
        }
    }
`;
