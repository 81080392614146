import React from "react";
import { CONTACTS } from "../../constants";
import { Row } from "../Row";
import { SInner, Wrapper, STitleText, ContentWrapper, SContactItem } from "./styled";

const WelcomeCareer: React.FC<{}> = () => {
  return (
    <Wrapper>
      <SInner>
        <ContentWrapper>
          <STitleText fontSize={75} fontWeight={300} as="div">Благодарим вас за интерес к Streamtech</STitleText>
          <Row alignItems="center">
            <SContactItem href={`tel:${CONTACTS.phone}`}>
              <div>{CONTACTS.phone}</div>
            </SContactItem>
            <SContactItem href={`mailto:${CONTACTS.email}`}>
              <div>{CONTACTS.email}</div>
            </SContactItem>
          </Row>
        </ContentWrapper>
      </SInner>
    </Wrapper>
  )
}

export default WelcomeCareer;
