import styled from 'styled-components';

interface IRow {
  alignItems?: string;
  justifyContent?: string;
}

export const Row = styled.div<IRow>`
  display: flex;
  flex: 1 0;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;