import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import AboutCompany from '../../components/AboutCompany';
import News from '../../components/News';
import Partners from '../../components/Partners';
import AboutContent from '../../components/AboutContent';
import WorkConditions from '../../components/WorkConditions';
import History from '../../components/History';

const AboutUsPage = () => (
    <>
      <Header isWhite />
        <AboutCompany/>
        <AboutContent>
          <Partners />
          <WorkConditions />
        </AboutContent>
        <History />
        <News/>
      <Footer/>
    </>
);

export default AboutUsPage;
