import styled from "styled-components";
import { Inner } from "../Inner";
import { Text } from '../Text';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.textBlackColor};
  border-radius: 0px 0px 50px 0px;
  padding: 190px 0 90px;
  &:after {
    background-image: url(${process.env.PUBLIC_URL + `/images/flow-about.svg`});
    background-position: left top;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 900px;
    width: 1110px;
  }

  @media (max-width: 1169px) {
    position: relative;
    &:after {
      height: 100%;
      width: 100%;
    }
  }
`;

export const SInner = styled(Inner)`
  padding: 0 35px;
`;


export const STitleText = styled(Text)`
  color: ${({ theme }) => theme.textWhiteColor};
  margin-bottom: 36px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
    font-size: 46px;
  }
`;

export const SSubText = styled(Text)`
  color: ${({ theme }) => theme.textSecondaryGrayColor};
  max-width: 1000px;
  @media (max-width: 768px) {
    max-width: none;
    font-size: 16px;
  }
`;