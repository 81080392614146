import React from "react";
import { SInner, SSubText, STitleText, Wrapper } from "./styled";

interface IBlackBlock {
  title: string;
  text?: string;
  content?: () => JSX.Element,
}

const BlackBlock: React.FC<IBlackBlock> = ({title, text, content}) => {
  return <Wrapper>
    <SInner>
      <STitleText fontSize={75} fontWeight={300} as="div">
        {title}
      </STitleText>
      <SSubText as="div">
        {content ? content() : text}
      </SSubText>
    </SInner>
  </Wrapper>;
}

export default BlackBlock;