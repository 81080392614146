import styles from './style.module.css'
import React from 'react'
import classNames from 'classnames'

interface IButton {
    text: string;
    isWhite?: boolean;
    onClick?: () => void;
}

function Button({ text, isWhite, onClick }: IButton){
    return(
        <button className={classNames(styles.button, isWhite && styles['_white'])} onClick={() => {
            onClick && onClick();
        }}>
            {text}
        </button>
    )
}

export default Button