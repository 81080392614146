/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import StyledFooter from './style'
import Wrapper from '../Wrapper'

function Footer(){
    return(
        <StyledFooter>
            <Wrapper>
                <div className="box">
                    <div className="col">
                        <div className="logo"></div>
                        <a href="#top" className="slogan">We build reliable Software</a>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h4 className="title">Помощь</h4>
                            {/* <a href="#top" className="li">Risk Disclosure</a> */}
                            <a href="/career" className="li">Карьера</a>
                            <a href="/#working-on" className="li">Наши проекты</a>
                            {/* <a href="#top" className="li">Investors</a>
                            <a href="#top" className="li">Terms & Policy</a> */}
                        </div>
                        <div className="col">
                            <h4 className="title">О нас</h4>
                            {/* <a href="#top" className="li">Payment & Tax</a>
                            <a href="#top" className="li">Term of Service</a> */}
                            <a href="/contacts" className="li">Контакты</a>
                            {/* <a href="#top" className="li">Announcements</a> */}
                            <a href="/#news" className="li">Новости</a>
                        </div>
                    </div>
                    <div className="col">
                        <h4 className="title">Наш адрес</h4>
                        <p className="adress">Российская Федерация, 117246, г. Москва, проезд Научный, д. 19, этаж 2, ком. 6д, офис 408</p>
                    </div>
                </div>
            </Wrapper>
            <div className="subfooter">Copyright ©Streamtech. {new Date().getUTCFullYear()}. All right reserved</div>
        </StyledFooter>
    )
}

export default Footer