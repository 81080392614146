export const list = [
    {
        title: 'наши технологии',
        icon_wide_1: 'rust.svg',
        paragraph_1: "Несколько лет назад мы выбрали RUST нашим основным языком программирования за отличную производительность и надежность и с тех пор не раз убеждались в абсолютной верности такого решения.",
    },
    {
        title: 'Наша ниша',
        h2_solo: 'Биржевые решения и блокчейн технологии', 
        paragraph_1: "Мы принесли в мир криптовалютных бирж и блокчейна опыт работы с классическими биржевыми решениями, умножили их на современные технологии разработки и достигли скорости и эффективности, на порядки превосходящей все, что было реализовано ранее.",
    },
    {
        title: 'Наша философия управления',
        h2_solo: 'Прозрачность и индивидуальный подход',
        paragraph_1: "Сложившийся в компании стиль управления сочетает в себе прозрачность, неформальность и индивидуальный подход для максимальной эффективности. Цели компании открыто обсуждаются с командой. Обратиться к руководству с любыми вопросами может любой сотрудник. Мы считаем, что только предоставив все необходимое каждому отдельному члену команды, можно рассчитывать на отличный результат.",
    },
    
];

