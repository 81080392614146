import React from "react";
import styled from 'styled-components'

// TODO specify map
const mapTypeOfTextToFont: {[key in string]: number} = {
  h1: 40,
  h2: 36,
  h3: 30,
  h4: 20,
  p1: 25,
  p2: 19,
  p3: 25,
  p4: 25,
}

interface IText {
  lineHeight?: string;
  fontSize?: number;
  fontWeight?: number;
  letterSpacing?: number;
  uppercase?: boolean;
  themeColor?: string;
}

export const Text = styled.span<IText>`
  line-height: ${({ lineHeight }) => lineHeight || '130%'};
  font-size: ${({ fontSize }) => fontSize || 19}px;
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  color: ${({ color, theme, themeColor }) => (themeColor && theme[themeColor]) ?? color ?? theme.textDarkColor};
  letter-spacing: ${({ letterSpacing }) => letterSpacing}px;

  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  text-decoration: none;
`;


// TODO: remove any
export const StdText = ({type, ...props}: any) => {
  const fontSize = mapTypeOfTextToFont[type]
  return <Text {...props} fontSize={fontSize}  />
}

