import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import BlackBlock from '../../components/BlackBlock';
import News from '../../components/News';
// import ExpandableList from '../../components/ExpandableList';
import PointsBlock from '../../components/PointsBlock';
import OfficeImg from './img/office.svg';
import HomeImg from './img/home.svg';
import MedicalImg from './img/medical.svg';
import BonusesImg from './img/bonuses.svg';
import Vacancies from '../../components/Vacancies';

// const projectList = [
//   {
//     title: 'Rust Developer',
//     // text: '––  Zubr',
//     content: () => (
//       <div>
//         <p>Мы предлагаем</p>
//         <ul>
//           <li>Работу в многогранной и талантливой команде разработчиков и product owner</li>
//           <li>Возможность для роста, обучения и экспериментов</li>
//           <li>Полное соблюдение ТК РФ</li>
//           <li>Расширенный ДМС после успешного прохождения испытательного срока</li>
//           <li>Ментор на весь период адаптации. </li>
//           <li>Посещение международных и российских профильных конференций</li>
//           <li>Повышение уровня технического английского за счет компании</li>
//           <li>Льготный процент кредитования от Тинькофф банка</li>
//         </ul>
//       </div>
//     )
//   },
//   {
//     title: 'DevOps',
//     // text: '––  Solana',
//     content: () => (
//       <div>
//         <p>Мы предлагаем</p>
//         <ul>
//           <li>Работу в многогранной и талантливой команде разработчиков и product owner</li>
//           <li>Возможность для роста, обучения и экспериментов</li>
//           <li>Полное соблюдение ТК РФ</li>
//           <li>Расширенный ДМС после успешного прохождения испытательного срока</li>
//           <li>Ментор на весь период адаптации. </li>
//           <li>Посещение международных и российских профильных конференций</li>
//           <li>Повышение уровня технического английского за счет компании</li>
//           <li>Льготный процент кредитования от Тинькофф банка</li>
//         </ul>
//       </div>
//     )
//   },
//   {
//     title: 'Python Developer',
//     // text: '––  Solana',
//     content: () => (
//       <div>
//         <p>Мы предлагаем</p>
//         <ul>
//           <li>Работу в многогранной и талантливой команде разработчиков и product owner</li>
//           <li>Возможность для роста, обучения и экспериментов</li>
//           <li>Полное соблюдение ТК РФ</li>
//           <li>Расширенный ДМС после успешного прохождения испытательного срока</li>
//           <li>Ментор на весь период адаптации. </li>
//           <li>Посещение международных и российских профильных конференций</li>
//           <li>Повышение уровня технического английского за счет компании</li>
//           <li>Льготный процент кредитования от Тинькофф банка</li>
//         </ul>
//       </div>
//     )
//   },
//   {
//     title: 'Test Engineer',
//     // text: '––  Solana',
//     content: () => (
//       <div>
//         <p>Мы предлагаем</p>
//         <ul>
//           <li>Работу в многогранной и талантливой команде разработчиков и product owner</li>
//           <li>Возможность для роста, обучения и экспериментов</li>
//           <li>Полное соблюдение ТК РФ</li>
//           <li>Расширенный ДМС после успешного прохождения испытательного срока</li>
//           <li>Ментор на весь период адаптации. </li>
//           <li>Посещение международных и российских профильных конференций</li>
//           <li>Повышение уровня технического английского за счет компании</li>
//           <li>Льготный процент кредитования от Тинькофф банка</li>
//         </ul>
//       </div>
//     )
//   },
// ];

const pointsList = [
  {
    title: "Мы поддерживаем баланс рабочего и личного времени каждого.",
    img: OfficeImg,
  },
  {
    title: "Заботимся о здоровье сотрудников",
    img: HomeImg,
  },
  {
    title: "Устраиваем корпоративные мероприятия и тимбилдинги",
    img: MedicalImg,
  },
  {
    title: "Помогаем молодым талантам развиваться и расти.",
    img: BonusesImg,
  }
];

const CareerPage = () => (
    <>
      <Header isWhite />
      <BlackBlock title="Карьера и работа" text="Мы фокусируемся на результате, а не на процессе. Уважаем и поощряем тех, кто растет вместе с нами, проявляет инициативу и вносит свой вклад в общее дело. Мы ищем смело мыслящих командных игроков, стремящихся к инновациям." />
      <Vacancies title="Наши вакансии" />
      <PointsBlock title="Условия труда" text="" list={pointsList} /> 
      <News/>
      <Footer/>
    </>
);

export default CareerPage;
