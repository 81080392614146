import styled from "styled-components";
import { Inner } from "../Inner";
import { Text, StdText} from '../Text';
import { Row } from '../Row';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.textBlackColor};
  border-radius: 50px 0px 0px 0px;
  padding: 80px 0;
`;

export const SInner = styled(Inner)`

  @media (max-width: 768px) {
    padding: 0 35px;
  }
`;

export const TextBlock = styled(Row)`
  padding-bottom: 55px;
  border-bottom: 1px solid #717171;
  align-items: flex-start;
  margin-bottom: 50px;

  @media (max-width: 1169px) {
    flex-direction: column;
  }
`;

export const STitle = styled(StdText)`
  margin-right: 45px;
`;

export const SText = styled(StdText)``;

export const PointList = styled(Row)`
  justify-content: space-between;
  @media (max-width: 1169px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PointItem = styled(Row)`
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1169px) {
    margin-right: 0;
    margin-bottom: 28px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PointImgContainer = styled.div`
  margin-right: 13px;
  background-color: ${({ theme }) => theme.textWhiteColor};
  width: 70px;
  min-width: 70px;
  height: 70px;
  min-height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
`;

export const PointText = styled(Text)``;
