import React, { useRef } from 'react';
import {
  Wrapper,
  SInner,
  STitleText,
  SSlider,
  SSubText,
  // SlickControlPanel,
  SlickWrapper,
  SRow,
  // EmployerImg,
  // SFutureText,
} from './styled';
import AboutQuote from './img/about-quote.svg';
import Employer1 from './img/samanta.png';
// import { Row } from '../Row';


const slides = [
  {
    text: 'С 2006 года наши основные создатели связали свою жизнь с технологиями и финансами. Накопленный опыт помог создать в России независимую компанию, привлекающую подряды у топовых заказчиков запада. Мы гордимся достигнутыми успехами.',
    icon: Employer1,
    name: 'Samanta Sanchez',
    placement: 'Marketing Manager',
  },
]


const AboutCompany = () => {
  const sliderRef = useRef<any>();
  const settings = {
    speed: 500,
    fade: true,
    arrows: false,
    slidesToScroll: 1,
    slidexToShow: 1,
  };
  return (
    <Wrapper>
      <SInner>
        <STitleText fontSize={75} fontWeight={300} as="div">
          О компании
        </STitleText>
        <SRow>
          <img src={AboutQuote} alt='' />
          <SlickWrapper>
            <SSlider ref={sliderRef} {...settings}>
              {slides.map((el, i) => <SSubText fontSize={25} key={i}>{el.text}</SSubText>)}
            </SSlider>

            {/* <Bullets>
                {slides.map(() => <div />)}
            </Bullets> */}
            {/* <SlickControlPanel>
              <Row>
                <EmployerImg src={Employer1} alt="" />
                <div>
                  <SFutureText themeColor="textWhiteColor" fontWeight={600} as="div">Samanta Sanchez</SFutureText>
                  <SFutureText themeColor="textSecondaryGrayColor" as="div">Marketing Manager</SFutureText>
                </div>
              </Row> */}
              {/* <Row>
                <PrevButton onClick={() => sliderRef.current.slickPrev()} />
                <NextButton onClick={() => sliderRef.current.slickNext()} />
              </Row> */}
            {/* </SlickControlPanel> */}
          </SlickWrapper>
        </SRow>

      </SInner>
    </Wrapper>
  )
}

export default AboutCompany;