
export const NAV_ITEMS = [
  {
		href: '/about-us',
		title: 'О нас',
	},
  {
		href: '/how-we-work',
		title: 'Как мы работаем',
	},
  {
		href: '/career',
		title: 'Карьера',
	},
  {
		href: '/contacts',
		title: 'Контакты',
	},
];

interface Project {
	link?: string;
	linkTitle?: string;
	title: string;
	description: string;
}

export const PROJECTS: Project[] = [
  {
    title: 'Texture Tranches',
    description: 'DeFi протокол, который реализует в экосистеме Solana финансовые инструменты с фиксированной доходностью и доходность с плечом, путем размещения депозитов в два типа траншей - Fixed yield и Leveraged yield.',
  },
  {
    title: 'Leveraged staking',
    description: 'Пример создания DeFi на основе блокчейна Solana. Протокол для фарминга $SOL с плечом и минимальным риском ликвидации.',
  },
]

export const CONTACTS = {
	phone: '+7 (495) 940-77-75',
	email: 'streamitech@gmail.com',
	telegram: 'https://t.me/HR_Tradetech',
}

export const HH_LINK = 'https://spb.hh.ru/employer/5721799';
