import styled from "styled-components";
import { Inner } from "../Inner";
import { StdText, Text } from "../Text";

interface IHistoryItem {
  reverse?: boolean;
}

export const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 0px 100px 0px 0px;
  padding: 118px 0 118px;
  @media (max-width: 1169px) {
    padding: 62px 20px;
  }
`;

export const SInner = styled(Inner)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1169px) {
    align-items: flex-start;
  }
`;

export const HistoryTitle = styled(StdText)`
  margin-bottom: 27px;
`;

export const SText = styled(Text)`
  text-align: center;
  max-width: 837px;
  @media (max-width: 1169px) {
    text-align: left;
  }
`;

export const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${process.env.PUBLIC_URL + `/images/history-grade.svg`});
  background-repeat: repeat-y;
  background-position: center center;
  @media (max-width: 1169px) {
    background-position: 138px center;
  }
  @media (max-width: 768px) {
    background-position: 0 center;
  }
`;


export const HistoryYear = styled.div`
  font-family: 'Rozha One', serif;
  font-size: 50px;
  line-height: 71px;
  color: ${({ theme }) => theme.textBlackColor};
  width: 518px;
  @media (max-width: 1169px) {
    width: auto;
    min-width: 100px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HistoryDash = styled.img`
  margin: 0 40px;

  @media (max-width: 768px) {
    margin: 0;
    margin-right: 40px;
  }
`;

export const HistoryTextBlock = styled.div`
  padding: 55px 78px 55px 37px;
  background: ${({ theme }) => theme.textBlackColor};
  border-radius: 20px;
  color: #F9F9F9CC;
  max-width: 518px;
  position: relative;
  &:before {
    content: '';
    border: 15px solid ${({ theme }) => theme.textBlackColor};
    position: absolute;
    top: 50%;
  }

  @media (max-width: 768px) {
    padding: 31px 20px;
  }
`;


export const HistoryItem = styled.div<IHistoryItem>`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  flex-direction: ${({ reverse }) => reverse ? 'row-reverse': 'row'};
  & ${HistoryYear} {
    text-align: ${({ reverse }) => reverse ? 'left': 'right'};
  }
  & ${HistoryTextBlock}:before {
    ${({ reverse }) => reverse ? `
      right: 0;
      transform: translate(50%, -50%) rotate(45deg);
    `: `
      left: 0;
      transform: translate(-50%, -50%) rotate(45deg);
    `}
  }
  &:first-child {
    margin-top: 48px;
  }

  @media (max-width: 1169px) {
    flex-direction: row;

    & ${HistoryYear} {
      text-align: right;
    }
    & ${HistoryTextBlock}:before {
      left: 0;
      right: auto;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`;

export const FutureBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1169px) {
    align-items: flex-start;
    width: 410px;
    margin-left: 123px;
  }

  @media (max-width: 768px) {
    width: auto;
    margin-left: 0;
  }
`;

export const CoolImg = styled.img`
  margin-bottom: 4px;
  width: 96px;
`;

export const FutureTitle = styled(StdText)`
  margin-bottom: 21px;
`;
