import React from 'react'
import StyledNews from './style' 
import Wrapper from '../Wrapper'
import Slider from 'react-slick'
import Slide from './Slide'

function News(){
    
        const settings = {
          arrows: true,  
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1169,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ],
        };

    return(
        <StyledNews id='news'>
            <Wrapper>
                <div className="header">
                    {/* <a href="#top" className="all">See all blog posts</a> */}
                    <h3 className="title">Последние новости</h3>
                </div>
                <Slider className='slick'
                {...settings}>
                    <Slide text="Команда начала работу над проектом Leveraged staking - фарминг $SOL с плечом и минимальным риском ликвидации."></Slide>
                    <Slide text="Проект Texture Tranches готовится к запуску в Mainnet."></Slide>
                    <Slide text="Стартовала работа над перспективным DeFi проектом - Texture Tranches."></Slide>
                </Slider>
            </Wrapper>


        </StyledNews>
    )
}

export default News