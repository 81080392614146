import styled from "styled-components";
import { Inner } from "../Inner";
import { StdText, Text } from "../Text";

interface IConditionWrapper {
  bottom?: boolean;
}

export const SInner = styled(Inner)`
  margin: 0 auto 68px;
`;

export const STitle = styled(StdText)`
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const ConditionsWrapper = styled.div`
  background: url(${process.env.PUBLIC_URL + `/images/conditions-background.svg`}) no-repeat;
  background-position: 0 36px;
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  @media (max-width: 1169px) {
    background-size: 100% auto;
    background-position: 0 26px
  }
  @media (max-width: 768px) {
    background-image: none;
    flex-wrap: wrap;
  }
`;

export const ConditionWrapper = styled.div<IConditionWrapper>`
  max-width: 275px;
  margin-top: ${({ bottom }) => bottom ? '20px': 0};

  @media (max-width: 1169px) {
    width: 235px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 26px;
    max-width: 45%;
  }
`;

export const ConditionIcon = styled.div`
  display: flex;
  width: 98px;
  height: 98px;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(148.02deg, #000000 32.55%, #616161 112.38%);
  border-radius: 10px 0px;
  margin-bottom: 20px;
  @media (max-width: 1169px) {
    width: 58px;
    height: 58px;
    & > img {
      width: 24px;
    }
  }
`;

export const ConditionTitle = styled(Text)`
  margin-bottom: 6px;
  @media (max-width: 1169px) {
    font-size: 14px;
  }
`;

export const ConditionText = styled(Text)`
  @media (max-width: 1169px) {
    font-size: 12px;
  }
`;
