import React from "react"
import { Wrapper, SInner, TextBlock, STitle, SText, PointList, PointItem, PointImgContainer, PointText } from "./styled";

interface IPointList {
  img: string;
  title: string;
}

interface IPointsBlock {
  title: string;
  text?: string;
  content?: () => JSX.Element;
  list: IPointList[];
}

const PointsBlock: React.FC<IPointsBlock> = ({ title, text, list, content }) => {
  return <Wrapper>
    <SInner>
      <TextBlock>
        <STitle type="h2" fontWeight={700} themeColor="textWhiteColor">{title}</STitle>
        <SText themeColor="textSecondaryWhiteColor">{content ? content() : text}</SText>
      </TextBlock>
      <PointList>
        {list.map((el, i) => (
          <PointItem>
            <PointImgContainer><img src={el.img} alt="" /></PointImgContainer>
            <PointText fontSize={24} fontWeight={600} themeColor="textWhiteColor">{el.title}</PointText>
          </PointItem>
        ))}
      </PointList>
    </SInner>
  </Wrapper>
};

export default PointsBlock;