import styled from "styled-components";
import { Inner } from "../Inner";
import { StdText, Text } from "../Text";

export const Wrapper = styled.div`
  padding: 130px 0;
`;

export const SInner = styled(Inner)`
  @media (max-width: 768px) {
    padding: 0 35px;
  }
`;

export const VacanciesTitle = styled(StdText)`
  margin-bottom: 25px;
`;

export const SText = styled(Text)`
  & > a {
    color: ${({ theme }) => theme.textBlackColor};
  }
`;
