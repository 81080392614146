import React from 'react';
import { SInner, Wrapper, SRow, TouchBlock, JoinBlock, STitle, SText, ContactRow, ContactItem, SSubTitle, SSubText, JoinTitle, JoinText, MapContainer } from './styled';
import EnvelopeImg from './img/fa-envelope.svg';
import PhoneImg from './img/fa-phone.svg';
import { CONTACTS } from '../../constants';

const CareerContent: React.FC<{}> = () => {
  return <Wrapper>
    <SInner>
      <SRow>
        <TouchBlock>
          <STitle fontSize={35} fontWeight={700} as="div">Оставайтесь на связи</STitle>
          <SText fontSize={25} lineHeight="31px" themeColor="textSecondaryDarkColor" as="div">Мы быстро развиваемся и растем. Если ты на гребне новых технологий и любишь решать нетривиальные задачи. Значит, с нами ты сможешь реализовать свой потенциал!</SText>
          <ContactRow>
            <ContactItem href={`tel:${CONTACTS.phone}`}>
              <img src={PhoneImg} alt="" />
              <div>{CONTACTS.phone}</div>
            </ContactItem>
            <ContactItem href={`mailto:${CONTACTS.email}`}>
              <img src={EnvelopeImg} alt="" />
              <div>{CONTACTS.email}</div>
            </ContactItem>
          </ContactRow>

          <SSubTitle fontSize={24} fontWeight={600} as="div">Карта</SSubTitle>
          <SSubText fontSize={19} lineHeight="25px" themeColor="textSecondaryDarkColor" as="div">Российская Федерация, 117246, г. Москва, проезд Научный, д. 19, этаж 2, ком. 6д, офис 408</SSubText>
          <MapContainer>
            <iframe title="Map" width="425" height="350" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} src="https://www.openstreetmap.org/export/embed.html?bbox=37.55022883415223%2C55.65167210725497%2C37.55752444267274%2C55.654486861385145&amp;layer=mapnik&amp;marker=55.65307950961434%2C37.553876638412476"></iframe>
          </MapContainer>
        </TouchBlock>
        <JoinBlock>
          <JoinTitle fontSize={30} fontWeight={700} lineHeight="38px" as="div">Хотите присоединиться к нашей команде?</JoinTitle>
          <JoinText fontSize={25} lineHeight="31px" themeColor="textSecondaryDarkColor" as="div">Отправьте свою информацию, и представитель Компании свяжется с вами</JoinText>

          <ContactItem href={`mailto:${CONTACTS.email}`}>
            <img src={EnvelopeImg} alt="" />
            <div>{CONTACTS.email}</div>
          </ContactItem>
        </JoinBlock>
      </SRow>
    </SInner>
  </Wrapper>
}

export default CareerContent;
