import React from 'react';
import { Wrapper, SRow } from './styled';
import Solana from './img/solana.png';
// import Neon from './img/neon.svg';
import Zubr from './img/zubr.svg';
import Rust from './img/rust.svg';

const partnerLogos = [
  Solana,
  Zubr,
  Rust,
]

const Partners: React.FC<{}> = () => {
  return <Wrapper>
    {/* <img src={PartnrsImg} alt="" /> */}
    <SRow>{partnerLogos.map(el => <img alt='' src={el} />)}</SRow>
  </Wrapper>;
}

export default Partners;