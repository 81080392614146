import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import News from '../../components/News';
import WelcomeCareer from '../../components/WelcomeCareer';
import CareerContent from '../../components/CareerContent';

const ContactsPage = () => (
    <>
      <Header isWhite />
      <WelcomeCareer />
      <CareerContent />
      <News/>
      <Footer/>
    </>
);

export default ContactsPage;
