import styled from 'styled-components'

export const BlockStyle = styled.div`
    display:flex;
    width:100%;
    height:100%;
    background:black;
    padding: 31px 24px 10px 31px;
    flex-direction:column;
    & .title{
        text-transform: uppercase;
        color: white;
        font-size: 15px;
        letter-spacing: 3px;
        margin: 0;
        margin-bottom: 18px;
        line-height: 18.86px;
    }
    & .paragraph{
        font-weight: 400;
        font-size: 19px;
        line-height: 25px;
        color: white;
        margin: 0;
        margin-bottom: 18px;
        opacity: 0.8;
    }
    & .icon_wide{
        display: block;
        height: 60px;
        width: auto;
        object-fit: contain;
        margin: 0;
        margin-bottom: 8px;
        margin-right: auto;
    }
    & .img_container {
        min-height: 80px;
        display: flex;
        margin-bottom: 10px;
    }
    & .inline{
        display:inline-block !important;
        margin-left: 10px;
        margin-bottom: 0px !important;
    }
    & .h2{
        margin: 0;
        margin-bottom: 10px;
        color: white;
        font-size: 30px;
        line-height: 25px;
        min-height: 80px;
    }
    & .icon_inline{
        margin-right: 10px;
    }
    & .logobox{
        display: flex;
        align-items: center;
        
    }
`;
