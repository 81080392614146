import React from 'react';
import Blocks from '../components/Blocks';
import Header from '../components/Header';
import Hero from '../components/Hero';
// import Employees from '../components/Employees';
// import Career from '../components/Career';
import '../index.css';
import News from '../components/News';
import Footer from '../components/Footer'
import WorkingOn from '../components/WorkingOn';
import Content from '../components/Content';

const IndexPage = () => (
    <>
      <Header />
      <Content>
        <Hero/>
        <Blocks/>
        <WorkingOn/>
        {/* <Employees/> */}
        {/* <Career/> */}
        <News/>
      </Content>
      <Footer/>
    </>
);

export default IndexPage;
