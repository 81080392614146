import styled from "styled-components";
import { Inner } from "../Inner";
import { Text } from '../Text';
import { Row } from '../Row';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.textWhiteColor};
`;

export const SInner = styled(Inner)`
  padding: 136px 0;

  @media (max-width: 768px) {
    padding: 136px 30px;
  }
`;

export const SRow = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 1169px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const TouchBlock = styled.div`
  max-width: 414px;
  @media (max-width: 1169px) {
    margin-bottom: 46px;
  }
  @media (max-width: 768px) {
    max-width: none;
  }
`;

export const STitle = styled(Text)`
  margin-bottom: 35px;
`;

export const SText = styled(Text)`
  margin-bottom: 40px;
`;

export const ContactRow = styled(Row)`
  padding-bottom: 34px;
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: 30px;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContactItem = styled.a`
  font-weight: normal;
  font-size: 19px;
  line-height: 25px;
  color: ${({ theme }) => theme.textSecondaryDarkColor};
  text-decoration: none;
  display: flex;
  & > img {
    margin-right: 14px;
  }
  @media (max-width: 768px) {
    margin: 0;
    margin-bottom: 21px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const SSubTitle = styled(Text)`
  margin-bottom: 12px;
`;

export const SSubText = styled(Text)`
  margin-bottom: 38px;
`;

export const JoinBlock = styled.div`
  background: #F3F3F3;
  border-radius: 0px 50px;
  padding: 52px 76px;
  max-width: 576px;
  @media (max-width: 768px) {
    padding: 52px 33px;
  }
`;

export const JoinTitle = styled(Text)`
  margin-bottom: 18px;
`;

export const JoinText = styled(Text)`
  margin-bottom: 25px;
`;

export const MapContainer = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;
