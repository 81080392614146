import styled from 'styled-components';
import { Inner } from '../Inner';
import { Row } from '../Row';

export const Wrapper = styled(Inner)`
  margin: 0 auto 50px;
  padding: 22px 0;
  border-bottom: 1px solid #BEBEBE;
  @media (max-width: 1169px) {
    overflow: auto;
  }
`;

export const SRow = styled(Row)`
  justify-content: space-between;
  & > img {
    transition: all .3s ease;
    max-height: 100px;
    max-width: 200px;
    margin-right: 20px;
    filter: grayscale(1);
    &:hover {
      filter: grayscale(0);
    }
    @media (max-width: 768px) {
      max-height: 78px;
    }
  }
`