import React from 'react'

function Slide(props){
    return(
        <div className="slide">
            <p className="slide__p">{props.text}</p>
            {/* <a href="#top" className="slide__link">learn more</a> */}
        </div>
    )
}

export default Slide