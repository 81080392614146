
import styled from 'styled-components';
import Slider from 'react-slick'
import { Text } from '../Text';
import { Row } from '../Row';
import { Inner } from '../Inner';

export const Wrapper = styled.div`
  height: 825px;
  background-color: ${({ theme }) => theme.textBlackColor};
  border-radius: 0px 0px 50px 0px;
  &:after {
    background-image: url(${process.env.PUBLIC_URL + `/images/flow-about.svg`});
    background-position: left top;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 900px;
    width: 1110px;
  }
  background: ${({ theme }) => theme.textBlackColor} url(${process.env.PUBLIC_URL + `/images/about-meet.png`}) no-repeat;
  background-size: 490px 680px;
  background-position-y: bottom;
  @media (max-width: 1169px) {
    background-image: none;
  }

  @media (max-width: 768px) {
    padding: 16px;
    height: 475px;
    position: relative;
    &:after {
      height: 100%;
      width: 100%;
    }
  }
`;

export const SInner = styled(Inner)`
  padding-top: 228px;
  padding-left: 320px;

  @media (max-width: 1169px) {
    padding-left: 0;
  }
  @media (max-width: 768px) {
    max-width: 360px;
    padding-top: 136px;
  }
`;

export const SlickWrapper = styled.div`
  max-width: 100%;
  z-index: 1; // NOTE: Why?
`;

export const STitleText = styled(Text)`
  color: ${({ theme }) => theme.textWhiteColor};
  margin-bottom: 36px;
  padding-left: 90px;
  @media (max-width: 1169px) {
    font-size: 64px;
  }

  @media (max-width: 768px) {
    font-size: 32px;
    padding-left: 42px
  }
`;

export const SSubText = styled(Text)`
  color: ${({ theme }) => theme.textSecondaryGrayColor};
  max-width: 617px;
  @media (max-width: 1169px) {
    font-size: 21px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 260px;
  }
`;

export const SRow = styled(Row)`
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 34px;
  & > img {
    margin-right: 30px;
  }
  @media (max-width: 1169px) {
    & > img {
      width: 118px;
    }
  }

  @media (max-width: 768px) {
    & > img {
      width: 60px;
      margin-right: 15px;
    }
  }
`;

export const SSlider = styled(Slider)`
  margin-bottom: 32px;
  & .slick-track {
    height: 100%;
    display: flex;
  }
`;

export const Bullets = styled.div`
  display: flex;
  margin-bottom: 32px;
  & > div {
    width: 124px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 14px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 1169px) {
    & > div {
      width: 106px;
    }
  }
  @media (max-width: 768px) {
    & > div {
      width: 52px;
    }
  }
`;

export const ControlButton = styled.div`
  color: transparent;
  border: none;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const PrevButton = styled(ControlButton)`
  height: 18px;
  width: 32px;
  margin-right: 32px;
  background: url(${process.env.PUBLIC_URL + `/images/arrow-left-w.svg`});

  @media (max-width: 768px) {
    height: 9px;
    width: 16px;
    background-size: cover;
    margin-right: 16px;
  }
`;

export const NextButton = styled(ControlButton)`
  width: 48px;
  height: 26px;
  background: url(${process.env.PUBLIC_URL + `/images/arrow-right-w.svg`});
  @media (max-width: 768px) {
    height: 13px;
    width: 24px;
    background-size: cover;
  }
`;

export const SlickControlPanel = styled.div`
  display: flex;
`;

export const EmployerImg = styled.img`
  margin-right: 20px;

  @media (max-width: 1169px) {
    width: 60px;
  }

  @media (max-width: 768px) {
    width: 30px;
  }
`;

export const SFutureText = styled(Text)`
  font-size: 19px;
  @media (max-width: 1169px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
